import { useRef, useState, useMemo, useCallback, useEffect } from 'react'

type Period = [number, number] // 내부 타입알리아스

const defaultOption: ActiveTimeOption = {
  periods: [[0, Infinity]],
  isOnce: false,
}

/**
 * getMappedPeriods
 * 옵션으로 받은 periods를 number 형태로 변환하고 지나간 period는 제거하여 리턴.
 *
 * @param periods {ActiveTimeOption['periods']}
 * @returns {Period[]}
 */
function getMappedPeriods(periods: ActiveTimeOption['periods']): Period[] {
  return periods.reduce<Period[]>((acc, [from, to]) => {
    const fromTime = from ? (typeof from === 'string' ? Math.floor(new Date(from).getTime() / 1000) : from) : 0
    const toTime = to ? (typeof to === 'string' ? Math.floor(new Date(to).getTime() / 1000) : to) : Infinity
    acc.push([fromTime, toTime])
    return acc
  }, [])
}

/**
 * getActiveTime
 * 매핑된 periods 내 현재 active 된 period의 index를 리턴.
 *
 * @param periods {ActiveTimeOption['periods']}
 * @returns {number}
 */
function getActiveTime(periods: Period[]): number {
  const now = Math.floor(Date.now() / 1000)
  return periods.findIndex(([from, to]) => from <= now && now <= to)
}

/**
 * useActiveTime
 * 동적으로 현재 시간이 입력된 from ~ to 사이의 시간인지의 여부를 넘겨준다.
 * 시간제 이벤트 등에 활용.
 *
 * @param option {ActiveTimeOption}
 * @returns {UseActiveTime}
 */
export function useActiveTime(option: ActiveTimeOption): UseActiveTime {
  const activeTimeOption = useMemo<ActiveTimeOption>(() => ({ ...defaultOption, ...option }), [option])
  const mappedPeriods = useRef<Period[]>([])
  const [activePeriodIndex, setActivePeriodIndex] = useState<number>(-1)
  const [isActive, setActive] = useState<boolean>(false)

  const reset = useCallback(() => {
    mappedPeriods.current = getMappedPeriods(activeTimeOption.periods)
    const nextMappedPeriodsIndex = getActiveTime(mappedPeriods.current)
    setActivePeriodIndex(nextMappedPeriodsIndex)
    setActive(nextMappedPeriodsIndex !== -1)
  }, [activeTimeOption.periods])

  useEffect(() => {
    reset()
  }, [reset])

  useEffect(() => {
    if (!activeTimeOption.isOnce && mappedPeriods.current.length > 0) {
      let tid: NodeJS.Timeout | null = setInterval(() => {
        // 1초 단위로 active period가 존재하는지 체크
        const currentActiveIndex = getActiveTime(mappedPeriods.current)
        setActivePeriodIndex(currentActiveIndex)

        if (currentActiveIndex !== -1) {
          setActive(true)
        } else {
          setActive(false)

          const now = Math.floor(Date.now() / 1000)
          const lastPeriod = mappedPeriods.current.length - 1

          if (now > mappedPeriods.current[lastPeriod][1]) {
            // 더 이상 활성기간 존재하지 않으면 타이머 종료
            clearInterval(tid!)
            tid = null
          }
        }
      }, 1000)

      return () => {
        if (tid) {
          clearInterval(tid)
          tid = null
        }
      }
    }
  }, [activeTimeOption, isActive])

  return [isActive, activePeriodIndex]
}
