import { Controller } from 'react-scrollmagic-r18'
import { HomePanel } from '@/features/home/components'
import { useContext, useEffect, useState } from 'react'
//import EventModal from '@/features/common/components/EventModal'
//import { EventContext } from '@/features/common/provider/EventProvider'
import NoticeModal from '@/features/common/components/NoticeModal'
import { useActiveTime } from '@/features/common/hooks/useActiveTime'
import dynamic from 'next/dynamic'

const Notice = dynamic(() => import('@/features/common/components/Notice0719'), { ssr: false })

function Home() {
  const noticePeriods: [string, string][] = [['2024-07-19 00:00:00', '2024-07-26 23:59:59']]
  //const { isShowModal } = useContext(EventContext)
  const [isOpened, setIsOpened] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isActiveTime] = useActiveTime({ periods: noticePeriods, isOnce: false })

  const handleClose = () => {
    setIsOpenModal(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsOpened(true)
      setIsOpenModal(true)
    }
  }, [])

  return (
    <Controller>
      <HomePanel />
      {/*{isShowModal && isOpened && <EventModal />}*/}
      {isOpenModal && isActiveTime && (
        <NoticeModal isOpened={isOpenModal} handleClose={handleClose}>
          <Notice />
        </NoticeModal>
      )}
    </Controller>
  )
}

export default Home
