import React from 'react'
import ReactModal from 'react-modal'
import style from './NoticeModal.module.scss'

import { useHideForSomeDay } from '@/features/common/hooks'

interface IBannerModalProps {
  isOpened: boolean
  children: React.ReactNode
  handleClose?: () => void
}

export default function NoticeModal({ isOpened, children, handleClose }: IBannerModalProps): JSX.Element {
  const { hideForOneDay, handleConditionalClose } = useHideForSomeDay('hideModal')
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: 'auto',
      maxHeight: 'auto',
      overflow: 'hidden',
      boxShadow: '0 10px 40px 10px rgba(0, 0, 0, 0.2)',
      border: '0px',
    },
    overlay: {
      display: 'contents',
      zIndex: 9999,
      borderRadius: '8px !important',
    },
  }

  if (hideForOneDay) {
    return <div />
  }

  return (
    <ReactModal isOpen={isOpened} style={customStyles}>
      <div>
        <div className={style.cc__notice__modal__wrapper}>
          <div className={style.notice__title}>
            <h4>[개인정보처리방침 개정 안내]</h4>
          </div>
          {children}
        </div>
        <div className={style.notice__button__wrap}>
          <p className={style.notice__button__no__show} onClick={handleConditionalClose}>
            <span>다시 보지않기</span>
          </p>
          <p className={style.notice__buton__close} onClick={handleClose}>
            <span>닫기</span>
          </p>
        </div>
      </div>
    </ReactModal>
  )
}
